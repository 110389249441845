import React from "react"
import VideoLayout from "./videoLayout"

function Dresses() {
  return (
    <VideoLayout title="Make-up and Dresses">
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091442/videos/makeup/vid-27_adi1xo.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091285/videos/makeup/vid-1_y3u4qw.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091303/videos/makeup/vid-4_wnlhlr.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091308/videos/makeup/vid-5_mtyeoq.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091314/videos/makeup/vid-6_foafuv.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091321/videos/makeup/vid-7_tizgbv.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091326/videos/makeup/vid-8_atsufg.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091333/videos/makeup/vid-9_zjpzbf.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091339/videos/makeup/vid-10_fghtto.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091344/videos/makeup/vid-11_ncfaqy.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091352/videos/makeup/vid-12_wt5fiy.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091358/videos/makeup/vid-13_azze0p.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091363/videos/makeup/vid-14_uonivj.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091369/videos/makeup/vid-15_vcrsya.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091377/videos/makeup/vid-16_wa2lni.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091290/videos/makeup/vid-2_pdnpxl.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091297/videos/makeup/vid-3_n1bmug.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091381/videos/makeup/vid-17_vv2lpc.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091386/videos/makeup/vid-18_xkazan.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091394/videos/makeup/vid-19_rwbtad.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091398/videos/makeup/vid-20_fvucn8.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091405/videos/makeup/vid-21_bkvwit.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091411/videos/makeup/vid-22_rbh0yt.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091416/videos/makeup/vid-23_jhcsw9.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091422/videos/makeup/vid-24_oew9ry.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091429/videos/makeup/vid-25_sicjfk.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871667/videos/makeup/bc739cc0c9ee4de80300808b2bb8b4_t7yxl3.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871703/videos/makeup/7996fbdbb399e0d8e1cc03d1e76a06_lm3y3q.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871736/videos/makeup/f07fb85c70a701bdc636bdf3e209a6_t362hr.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871860/videos/makeup/86a9029888bb28c434dd4724c08122_rbhq1q.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </VideoLayout>
  )
}

export default Dresses
